import React, { useState, useEffect, useCallback } from 'react';
import Joyride, { STATUS } from 'react-joyride';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loader from '../../loader/Loader';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SerialNumberBadge from '../../components/product/SerialNumberBadge';
import styles from './OrderForm.module.css';
import { 
  RiMapPinLine, 
  RiCalendarLine,
  RiEdit2Line,
  RiDeleteBin6Line,
  RiFileListLine,
  RiInformationLine 
} from 'react-icons/ri';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const CreateOrder = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [runTour, setRunTour] = useState(false);
  const [formData, setFormData] = useState({
    orderName: '',
    destination: '',
    fromDate: '',
    untilDate: '',
    products: [],
    requestedBy: ''
  });

  // Product states
  const [availableProducts, setAvailableProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedSerials, setSelectedSerials] = useState([]);
  const [allSerials, setAllSerials] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const [quantityError, setQuantityError] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [editingProduct, setEditingProduct] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);
  const [dateError, setDateError] = useState('');
  const [originalQuantities, setOriginalQuantities] = useState({});
  const [originalSerialNumbers, setOriginalSerialNumbers] = useState({});

  const indexOfLastProduct = currentPage * itemsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - itemsPerPage;
  const currentProducts = filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct);
  const totalPages = Math.ceil(filteredProducts.length / itemsPerPage);

  const steps = [
    {
        target: `.${styles.card}`,
        content: 'Welcome to Order Creation! This guide will help you understand how orders work.',
        placement: 'center',
        disableBeacon: true,
    },
    {
        target:  `.${styles.formGrid}`,
        content: 'Step 1: Fill in the basic details of your order here. Once submitted, the order will be in "Committed" status.',
        placement: 'bottom',
    },
    {
        target: `.${styles.productsTableContainer}`, 
        content: 'Step 2: Browse and select products for your order. Reserved products cannot be assigned to other orders.',
        placement: 'top',
    },
    {
        target: `.${styles.card}`,
        content: 'Order Status Explained:\n\n' +
                '• Committed Status: Products are reserved exclusively for your order and cannot be assigned to others.\n' +
                '• Issued Status: When reaching the order\'s start date, the status automatically changes to "Issued" - meaning the order is now active.\n\n' +
                'Note: You can edit the order details at any time before the status changes to "Issued".',
        placement: 'center',
        disableBeacon: true,
        styles: {
            tooltip: {
                width: '450px',
                 whiteSpace: 'pre-line'
            }
        }
    },
    {
        target: `.${styles.formActions}`, 
        content: 'Final Step: Review and submit your order. Remember, the status will automatically change to "Issued" on the start date.\n\n' + 
                'Ensure you return the products to the warehouse by marking the order as "Returned\n\n' +
                'You can view all your orders in the "Orders Dashboard" page.',
        placement: 'top',
        styles: {
            tooltip: {
                width: '450px',
                 whiteSpace: 'pre-line'
            }
        }
    }
    ];

    useEffect(() => {
        const hasSeenGuide = localStorage.getItem('hasSeenOrderGuide');
        if (!hasSeenGuide) {
            setRunTour(true);
        }
    }, []);

    

  // Filter states
  const [filters, setFilters] = useState({
    category: '',
    warehouse: '',
    department: ''
  });

  const applyFilters = useCallback(() => {
    let filtered = [...availableProducts];
  
    if (searchTerm) {
      filtered = filtered.filter(product => 
        product.productName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        product.productCode.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
  
    if (filters.category) {
      filtered = filtered.filter(product => 
        product.categoryName === filters.category
      );
    }
  
    if (filters.department) {
      filtered = filtered.filter(product => 
        product.departmentName === filters.department
      );
    }
  
    if (filters.warehouse) {
      filtered = filtered.filter(product => 
        product.warehouseName === filters.warehouse
      );
    }
  
    setFilteredProducts(filtered);
  }, [availableProducts, filters, searchTerm]);

  useEffect(() => {
    fetchProducts();
    fetchUser();
  }, []);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchTerm,filters]);


  useEffect(() => {
    applyFilters();
  }, [filters, searchTerm, availableProducts, applyFilters]);

  const fetchProducts = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BACKEND_URL}/api/products/getproducts`);
      const products = response.data || [];

      const originals = products.reduce((acc, product) => {
        acc[product._id] = product.quantityAvailable;
        return acc;
      }, {});

      const originalSerials = products.reduce((acc, product) => {
        if (product.managedBySerial) {
          acc[product._id] = product.serialNumberAvailable;
        }
        return acc;
      }, {});

      setOriginalSerialNumbers(originalSerials);
      setOriginalQuantities(originals);

      // Fetch warehouse names
      const warehouseIds = [...new Set(products.map(p => p.warehouse))];
      const warehouseResponses = await Promise.all(
        warehouseIds.map(id => axios.get(`${BACKEND_URL}/api/warehouses/${id}`))
      );
      const warehouses = warehouseResponses.reduce((acc, res) => {
        acc[res.data._id] = res.data.name;
        return acc;
      }, {});

      // Fetch category names
      const categoryIds = [...new Set(products.map(p => p.category))];
      const categoryResponses = await Promise.all(
        categoryIds.map(id => axios.get(`${BACKEND_URL}/api/categories/${id}`))
      );
      const categories = categoryResponses.reduce((acc, res) => {
        acc[res.data._id] = res.data.name;
        return acc;
      }, {});

      // Fetch department names
      const departmentIds = [...new Set(products.map(p => p.department))];
      const departmentResponses = await Promise.all(
        departmentIds.map(id => axios.get(`${BACKEND_URL}/api/departments/${id}`))
      );
      const departments = departmentResponses.reduce((acc, res) => {
        acc[res.data._id] = res.data.name;
        return acc;
      }, {});

      // Add warehouse and category names to products
      const productsWithNames = products.map(product => ({
        ...product,
        warehouseName: warehouses[product.warehouse],
        categoryName: categories[product.category],
        departmentName: departments[product.department]
      }));

      setAvailableProducts(productsWithNames);
      setFilteredProducts(productsWithNames);
    } catch (error) {
      toast.error("Failed to fetch products");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchUser = async () => {
    try {
      const response = await axios.get(`${BACKEND_URL}/api/users/getuser/current-user`);
      setFormData(prev => ({ ...prev, requestedBy: response.data }));
    } catch (error) {
      toast.error("Failed to fetch user information");
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  // Date validation
    if (name === 'untilDate' || name === 'fromDate') {
      setDateError('');
    }
  };

  const handleSerialChange = (e) => {
    const serial = e.target.value;
    if (serial) {
      setSelectedSerials(prev => [...prev, serial]);
      setAllSerials(prev => prev.filter(s => s !== serial));
      setQuantity(prev => prev + 1);
    }
  };

  const handleRemoveSerial = (serial) => {
    setSelectedSerials(prev => prev.filter(s => s !== serial));
    setAllSerials(prev => [...prev, serial]);
    setQuantity(prev => prev - 1);
  };

  const handleRemoveProduct = (productId) => {
    // Find the product being removed
    const removedProduct = formData.products.find(p => p.product === productId);
    const productInList = availableProducts.find(p => p._id === productId);
    
    if (productInList.managedBySerial) {
        // For serial number products
        const remainingProducts = formData.products.filter(p => p.product !== productId);
        const usedSerials = remainingProducts
            .filter(p => p.product === productId)
            .flatMap(p => p.serialNumbers);
            
        const availableSerials = originalSerialNumbers[productId]
            .filter(serial => !usedSerials.includes(serial));

        setFilteredProducts(prev => prev.map(p => {
            if (p._id === productId) {
                return {
                    ...p,
                    quantityAvailable: availableSerials.length,
                    serialNumberAvailable: availableSerials
                };
            }
            return p;
        }));

        setAvailableProducts(prev => prev.map(p => {
            if (p._id === productId) {
                return {
                    ...p,
                    quantityAvailable: availableSerials.length,
                    serialNumberAvailable: availableSerials
                };
            }
            return p;
        }));
    } else {
        // For regular products (existing logic)
        setFilteredProducts(prev => prev.map(p => {
            if (p._id === productId) {
                return { ...p, quantityAvailable: p.quantityAvailable + removedProduct.quantity };
            }
            return p;
        }));

        setAvailableProducts(prev => prev.map(p => {
            if (p._id === productId) {
                return { ...p, quantityAvailable: p.quantityAvailable + removedProduct.quantity };
            }
            return p;
        }));
    }

    setFormData(prev => ({
        ...prev,
        products: prev.products.filter(p => p.product !== productId),
    }));
};

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Date validation
    if (formData.fromDate && formData.untilDate && 
        new Date(formData.untilDate) <= new Date(formData.fromDate)) {
      setDateError('Until date must be after from date');
      return;
    }
    if (formData.products.length === 0) {
      toast.error('Please add at least one product to the order');
      return;
    }

    setIsLoading(true);
    try {
      await axios.post(`${BACKEND_URL}/api/orders`, formData);
      toast.success('Order created successfully!');
      navigate('/orders');
    } catch (error) {
      toast.error(error.response?.data?.message || 'Failed to create order');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSelectProductFromTable = (product) => {
    setSelectedProduct(product);
    if (product.managedBySerial) {

        const selectedSerialsInOrder = formData.products
        .filter(p => p.product === product._id)
        .flatMap(p => p.serialNumbers);
      
      const availableSerials = originalSerialNumbers[product._id]
        .filter(serial => !selectedSerialsInOrder.includes(serial));

        setAllSerials(availableSerials);
        setSelectedSerials([]);
        setQuantity(0);

        setFilteredProducts(prev => prev.map(p => {
        if (p._id === product._id) {
          return { ...p, quantityAvailable: availableSerials.length };
        }
        return p;
      }));
    } else {
      setQuantity(1);
    }
  };

  const handleQuantityChange = (e) => {
    let value = parseInt(e.target.value) || 0;
    const product = selectedProduct || availableProducts.find(p => p._id === editingProduct?.product);
  
    if (product) {
      if (value < 1) {
        value = 1;
      } else if (value > product.quantityAvailable) {
        value = product.quantityAvailable;
        setQuantityError(`Maximum available quantity is ${product.quantityAvailable}`);
      } else {
        setQuantityError('');
      }
  
      setQuantity(value);
    }
  };

  // Add edit handler
  const handleEditProduct = (productToEdit) => {
    setEditingProduct(productToEdit);
    const product = availableProducts.find(p => p._id === productToEdit.product);
    
    // Calculate the true available quantity:
    // Original quantity - (quantities of other products in the order) + (quantity of the product being edited)
    const otherProductsQuantity = formData.products
        .filter(p => p.product !== productToEdit.product)
        .reduce((acc, p) => {
            if (p.product === product._id) {
                return acc + p.quantity;
            }
            return acc;
        }, 0);

    const trueAvailableQuantity = originalQuantities[product._id] - otherProductsQuantity;

    // Update the displayed available quantity
    setFilteredProducts(prev => prev.map(p => {
        if (p._id === productToEdit.product) {
            return { ...p, quantityAvailable: trueAvailableQuantity };
        }
        return p;
    }));

    setAvailableProducts(prev => prev.map(p => {
        if (p._id === productToEdit.product) {
            return { ...p, quantityAvailable: trueAvailableQuantity };
        }
        return p;
    }));

    setSelectedProduct({
        ...product,
        quantityAvailable: trueAvailableQuantity
    });
    setQuantity(productToEdit.quantity);
    setSelectedSerials(productToEdit.serialNumbers || []);
    
    if (product.managedBySerial) {
        const availableSerials = product.serialNumberAvailable.filter(
            serial => !productToEdit.serialNumbers.includes(serial)
        );
        setAllSerials([...availableSerials]);
    }
  };

  const handleJoyrideCallback = (data) => {
    const { status } = data;
        if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
            localStorage.setItem('hasSeenOrderGuide', 'true');
            setRunTour(false);
        }
    };

  const handleCancelEdit = () => {
    // First, let's calculate the total quantity of this product currently in the order
    const currentProduct = editingProduct;
    const totalOrderedQuantity = formData.products
        .filter(p => p.product === currentProduct.product)
        .reduce((acc, p) => acc + p.quantity, 0);

    // Now update the displayed quantities to show the correct available amount
    // by subtracting the ordered quantity from the original quantity
    setFilteredProducts(prev => prev.map(p => {
        if (p._id === currentProduct.product) {
            return { 
                ...p, 
                quantityAvailable: originalQuantities[p._id] - totalOrderedQuantity 
            };
        }
        return p;
    }));

    setAvailableProducts(prev => prev.map(p => {
        if (p._id === currentProduct.product) {
            return { 
                ...p, 
                quantityAvailable: originalQuantities[p._id] - totalOrderedQuantity 
            };
        }
        return p;
    }));

    // Reset all edit-related states
    setEditingProduct(null);
    setSelectedProduct(null);
    setSelectedSerials([]);
    setQuantity(1);
  };

  const handleAddProduct = () => {
    if (!selectedProduct) return;

    if (selectedProduct.managedBySerial && selectedSerials.length === 0) {
        toast.error('Please select at least one serial number');
        return;
    }

    if (!selectedProduct.managedBySerial && quantity === 0) {
        toast.error('Please select a quantity greater than 0');
        return;
    }


    const productData = {
        product: selectedProduct._id,
        productName: selectedProduct.productName,
        productCode: selectedProduct.productCode,
        productPrice: selectedProduct.price,
        productWeight: selectedProduct.weight,
        quantity: selectedProduct.managedBySerial ? selectedSerials.length : quantity,
        initialQuantity: selectedProduct.managedBySerial ? selectedSerials.length : quantity,
        returnedQuantity: 0,
        missingQuantity: 0,
        serialNumbers: selectedSerials,
        returnedSerialNumbers: [],
        missingSerialNumbers: []
    };

    if (editingProduct) {
        if (selectedProduct.managedBySerial) {
            // Handle serial number product editing
            const otherProductsSerials = formData.products
                .filter(p => p.product === selectedProduct._id && p.product !== editingProduct.product)
                .flatMap(p => p.serialNumbers);

            const allUsedSerials = [...otherProductsSerials, ...selectedSerials];
            const remainingSerials = originalSerialNumbers[selectedProduct._id]
                .filter(serial => !allUsedSerials.includes(serial));

            setFilteredProducts(prev => prev.map(p => {
                if (p._id === selectedProduct._id) {
                    return {
                        ...p,
                        quantityAvailable: remainingSerials.length,
                        serialNumberAvailable: remainingSerials
                    };
                }
                return p;
            }));

            setAvailableProducts(prev => prev.map(p => {
                if (p._id === selectedProduct._id) {
                    return {
                        ...p,
                        quantityAvailable: remainingSerials.length,
                        serialNumberAvailable: remainingSerials
                    };
                }
                return p;
            }));
        } else {
            // Handle regular product editing
            const otherProductsQuantity = formData.products
                .filter(p => p.product !== editingProduct.product)
                .reduce((acc, p) => {
                    if (p.product === selectedProduct._id) {
                        return acc + p.quantity;
                    }
                    return acc;
                }, 0);

            const newAvailableQuantity = originalQuantities[selectedProduct._id] - otherProductsQuantity - productData.quantity;

            if (newAvailableQuantity < 0) {
                toast.error(`Cannot add more than ${originalQuantities[selectedProduct._id] - otherProductsQuantity} units`);
                return;
            }

            setFilteredProducts(prev => prev.map(p => {
                if (p._id === selectedProduct._id) {
                    return { ...p, quantityAvailable: newAvailableQuantity };
                }
                return p;
            }));

            setAvailableProducts(prev => prev.map(p => {
                if (p._id === selectedProduct._id) {
                    return { ...p, quantityAvailable: newAvailableQuantity };
                }
                return p;
            }));
        }

        setFormData(prev => ({
            ...prev,
            products: prev.products.map(p => 
                p.product === editingProduct.product ? productData : p
            ),
        }));
    } else {
        if (selectedProduct.managedBySerial) {
            // Check if product already exists in formData
            const existingProduct = formData.products.find(p => p.product === selectedProduct._id);
            
            if (existingProduct) {
                // Merge serial numbers with existing product
                const updatedSerialNumbers = [...existingProduct.serialNumbers, ...selectedSerials];
                
                setFormData(prev => ({
                    ...prev,
                    products: prev.products.map(p => 
                        p.product === selectedProduct._id 
                            ? {
                                ...p,
                                quantity: updatedSerialNumbers.length,
                                initialQuantity: updatedSerialNumbers.length,
                                serialNumbers: updatedSerialNumbers
                            }
                            : p
                    ),
                }));
            } else {
                // Add as new product
                setFormData(prev => ({
                    ...prev,
                    products: [...prev.products, productData],
                }));
            }

            // Update available serials and quantities in display
            const existingSerials = formData.products
                .filter(p => p.product === selectedProduct._id)
                .flatMap(p => p.serialNumbers);

            const allUsedSerials = [...existingSerials, ...selectedSerials];
            const remainingSerials = originalSerialNumbers[selectedProduct._id]
                .filter(serial => !allUsedSerials.includes(serial));

            setFilteredProducts(prev => prev.map(p => {
                if (p._id === selectedProduct._id) {
                    return {
                        ...p,
                        quantityAvailable: remainingSerials.length,
                        serialNumberAvailable: remainingSerials
                    };
                }
                return p;
            }));

            setAvailableProducts(prev => prev.map(p => {
                if (p._id === selectedProduct._id) {
                    return {
                        ...p,
                        quantityAvailable: remainingSerials.length,
                        serialNumberAvailable: remainingSerials
                    };
                }
                return p;
            }));
        } else {
            // Handle regular product adding
            const existingProduct = formData.products.find(p => p.product === selectedProduct._id);
            
            if (existingProduct) {
                // Calculate available quantity based on original quantity
                const maxAvailable = originalQuantities[selectedProduct._id] - 
                    formData.products
                        .filter(p => p.product === selectedProduct._id && p !== existingProduct)
                        .reduce((acc, p) => acc + p.quantity, 0);

                const newQuantity = existingProduct.quantity + productData.quantity;

                if (newQuantity > maxAvailable) {
                    toast.error(`Cannot add more than ${maxAvailable} units`);
                    return;
                }

                // Update formData with accumulated quantity
                setFormData(prev => ({
                    ...prev,
                    products: prev.products.map(p => 
                        p.product === selectedProduct._id 
                            ? { ...p, quantity: newQuantity, initialQuantity: newQuantity }
                            : p
                    ),
                }));
            } else {
                // Add new product
                setFormData(prev => ({
                    ...prev,
                    products: [...prev.products, productData],
                }));
            }

            // Update displayed quantities
            const newAvailableQuantity = originalQuantities[selectedProduct._id] - 
                formData.products
                    .filter(p => p.product === selectedProduct._id)
                    .reduce((acc, p) => acc + p.quantity, 0) - 
                productData.quantity;

            setFilteredProducts(prev => prev.map(p => {
                if (p._id === selectedProduct._id) {
                    return { ...p, quantityAvailable: newAvailableQuantity };
                }
                return p;
            }));

            setAvailableProducts(prev => prev.map(p => {
                if (p._id === selectedProduct._id) {
                    return { ...p, quantityAvailable: newAvailableQuantity };
                }
                return p;
            }));
        }
    }

    // Reset states
    setSelectedProduct(null);
    setSelectedSerials([]);
    setQuantity(1);
    setEditingProduct(null);
};

  // Add pagination controls component
  const PaginationControls = () => (
    <div className={styles.paginationControls}>
        <button
            onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
            className={styles.paginationBtn}
        >
            Previous
        </button>
        <span className={styles.pageInfo}>
            Page {currentPage} of {totalPages > 0 ? totalPages : 1}
        </span>
        <button
            onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
            disabled={currentPage === totalPages || totalPages === 0}
            className={styles.paginationBtn}
        >
            Next
        </button>
    </div>
);

const ProductsTable = () => (
  <div className={styles.productsTableContainer}>
      <table className={styles.productsTable}>
          <thead>
              <tr>
                  <th>Product Code</th>
                  <th>Product Name</th>
                  <th>Category</th>
                  <th>Warehouse</th>
                  <th>Department</th>
                  <th>Available Quantity</th>
                  <th>Action</th>
              </tr>
          </thead>
          <tbody>
              {currentProducts.length > 0 ? (
                  currentProducts.map(product => (
                      <tr 
                          key={product._id}
                          className={selectedProduct?._id === product._id ? styles.selectedRow : ''}
                      >
                          <td>{product.productCode}</td>
                          <td>{product.productName}</td>
                          <td>{product.categoryName}</td>
                          <td>{product.warehouseName}</td>
                          <td>{product.departmentName}</td>
                          <td className={`${styles.quantityCell} ${
                              !product.managedBySerial && (
                                product.quantityAvailable === 0 ? styles.outOfStock :
                                product.quantityAvailable < 10 ? styles.lowStock : ''
                            )
                          }`}>
                            {product.managedBySerial 
                                ? `${product.serialNumberAvailable.length} units` 
                                : product.quantityAvailable}
                          </td>
                          <td>
                              <button
                                  type="button"
                                  onClick={() => handleSelectProductFromTable(product)}
                                  className={styles.selectProductBtn}
                                  disabled={product.quantityAvailable === 0}
                              >
                                  Select
                              </button>
                          </td>
                      </tr>
                  ))
              ) : (
                  <tr>
                      <td colSpan="7" className={styles.noProducts}>No products available</td>
                  </tr>
              )}
          </tbody>
      </table>
      <PaginationControls />
  </div>
);


const SelectedProductsTable = ({ availableProducts }) => (
  <div className={styles.selectedProductsTable}>
      <h3>Selected Products</h3>
      <table>
          <thead>
              <tr>
                  <th>Product Name</th>
                  <th>Product Code</th>
                  <th>Department</th>
                  <th>Quantity</th>
                  <th>Serial Numbers</th>
                  <th>Actions</th>
              </tr>
          </thead>
          <tbody>
            {formData.products.map(product => {
                  const productDetails = availableProducts.find(p => p._id === product.product);
                  return (
                  <tr key={product.product}>
                      <td>{product.productName}</td>
                      <td>{product.productCode}</td>
                      <td>{productDetails?.departmentName || '-'}</td>
                      <td>{product.quantity}</td>
                      <td>
                        <div className={styles.serialList}>
                          {product.serialNumbers.map(serial => (
                              <SerialNumberBadge 
                                  key={serial}
                                  serialNumber={serial}
                                  isRemovable={false}
                              />
                          ))}
                        </div>
                      </td>
                      <td className={styles.actionColumn}>
                        <RiEdit2Line
                            className={`${styles.actionIcon} ${styles.editIcon}`}
                            onClick={() => handleEditProduct(product)}
                        />
                        <RiDeleteBin6Line
                            className={`${styles.actionIcon} ${styles.deleteIcon}`}
                            onClick={() => handleRemoveProduct(product.product)}
                        />
                      </td>
                  </tr>
                    );
                })}
          </tbody>
      </table>
  </div>
);

return (
    <>
    <Joyride
        steps={steps}
        run={runTour}
        continuous={true}
        showProgress={true}
        showSkipButton={true}
        callback={handleJoyrideCallback}
        styles={{
            options: {
                primaryColor: '#3498db',
                zIndex: 1000,
            },
        }}
    />
  <div className={styles.container}>
      {isLoading && <Loader />}

      <div className={styles.card}>
          <div className={styles.cardHeader}>
            <div className={styles.headerContent}>
                <h2>Create New Order</h2>
                <button 
                    className={styles.infoButton}
                    onClick={() => setRunTour(true)}
                    title="Show Guide"
                >
                    <RiInformationLine />
                </button>
            </div>
          </div>
          
          <form onSubmit={handleSubmit} className={styles.form}>
              {/* Basic Order Info */}
              <div className={styles.formGrid}>
                  <div className={styles.inputGroup}>
                      <label className={styles.inputLabel}>
                          Order Name <span className={styles.required}>*</span>
                      </label>
                      <div className={styles.iconInput}>
                        <RiFileListLine className={styles.inputIcon} />       
                        <input
                            type="text"
                            name="orderName"
                            value={formData.orderName}
                            onChange={handleInputChange}
                            placeholder="Enter order name"
                            className={styles.input}
                            required
                        />
                      </div>
                  </div>
                  
                  <div className={styles.inputGroup}>
                      <label className={styles.inputLabel}>
                          Destination <span className={styles.required}>*</span>
                      </label>
                      <div className={styles.iconInput}>
                          <RiMapPinLine className={styles.inputIcon} />
                          <input
                              type="text"
                              name="destination"
                              value={formData.destination}
                              onChange={handleInputChange}
                              placeholder="Enter destination"
                              className={styles.input}
                              required
                          />
                      </div>
                  </div>

                  <div className={styles.inputGroup}>
                      <label className={styles.inputLabel}>
                          From Date <span className={styles.required}>*</span>
                      </label>
                      <div className={styles.iconInput}>
                          <RiCalendarLine className={styles.inputIcon} />
                          <DatePicker
                            selected={formData.fromDate}
                            onChange={date => handleInputChange({
                                target: { name: 'fromDate', value: date }
                            })}
                            dateFormat="dd/MM/yyyy"
                            placeholderText="dd/mm/yyyy"
                            className={styles.input}
                            required
                            showYearDropdown
                            scrollableYearDropdown
                            yearDropdownItemNumber={15}
                            showPopperArrow={false}
                          />
                      </div>
                  </div>

                  <div className={styles.inputGroup}>
                      <label className={styles.inputLabel}>
                          Until Date <span className={styles.required}>*</span>
                      </label>
                      <div className={styles.iconInput}>
                          <RiCalendarLine className={styles.inputIcon} />
                          <DatePicker
                            selected={formData.untilDate}
                            onChange={date => handleInputChange({
                                target: { name: 'untilDate', value: date }
                            })}
                            dateFormat="dd/MM/yyyy"
                            placeholderText="dd/mm/yyyy"
                            className={styles.input}
                            required
                            showYearDropdown
                            scrollableYearDropdown
                            yearDropdownItemNumber={15}
                            showPopperArrow={false}
                          />
                      </div>
                      {dateError && <span className={styles.errorMessage}>{dateError}</span>}
                  </div>
              </div>

              {/* Search and Filters Section */}
              <div className={styles.searchSection}>
                  <div className={styles.filtersPanel}>
                      <div className={styles.filterGroup}>
                          <label className={styles.filterLabel}>Search Products</label>
                          <input
                              type="text"
                              value={searchTerm}
                              onChange={(e) => setSearchTerm(e.target.value)}
                              placeholder="Search by name or code..."
                              className={styles.searchInput}
                          />
                      </div>

                      {/* Filters */}
                      {['category', 'department', 'warehouse'].map(filterType => (
                          <div key={filterType} className={styles.filterGroup}>
                              <label className={styles.filterLabel}>
                                  {filterType.charAt(0).toUpperCase() + filterType.slice(1)}
                              </label>
                              <select
                                  value={filters[filterType]}
                                  onChange={(e) => setFilters(prev => ({ 
                                      ...prev, 
                                      [filterType]: e.target.value 
                                  }))}
                                  className={styles.select}
                              >
                                  <option value="">All {filterType.charAt(0).toUpperCase() + filterType.slice(1)}s</option>
                                  {[...new Set(availableProducts.map(p => p[`${filterType}Name`]))]
                                      .filter(Boolean)
                                      .sort()
                                      .map(value => (
                                          <option key={value} value={value}>{value}</option>
                                      ))
                                  }
                              </select>
                          </div>
                      ))}
                  </div>
              </div>

              <ProductsTable 
                  currentProducts={currentProducts}
                  selectedProduct={selectedProduct}
                  handleSelectProductFromTable={handleSelectProductFromTable}
              />

              {/* Product Selection Panel */}
              {selectedProduct && (
                  <div className={styles.productSelectionPanel}>
                      <div className={styles.selectedProductHeader}>
                          <h3>Selected Product: {selectedProduct.productName}</h3>
                          {editingProduct && (
                              <span className={styles.editingBadge}>Editing</span>
                          )}
                      </div>
                      
                      {selectedProduct.managedBySerial ? (
                          <div className={styles.serialSelection}>
                              <select 
                                  value="" 
                                  onChange={handleSerialChange}
                                  className={styles.serialSelect}
                              >
                                  <option value="">Select Serial Number</option>
                                  {allSerials.map(serial => (
                                      <option key={serial} value={serial}>{serial}</option>
                                  ))}
                              </select>
                              
                              <div className={styles.serialTags}>
                                  {selectedSerials.map(serial => (
                                      <SerialNumberBadge 
                                          key={serial} 
                                          serialNumber={serial} 
                                          onRemove={handleRemoveSerial} 
                                          isRemovable={true} 
                                      />
                                  ))}
                              </div>
                          </div>
                      ) : (
                          <div className={styles.quantityInputGroup}>
                              <label>Quantity:</label>
                              <input
                                  type="number"
                                  value={quantity}
                                  onChange={handleQuantityChange}
                                  min="1"
                                  max={selectedProduct.quantityAvailable}
                                  className={styles.quantityInput}
                              />
                              {quantityError && (
                                  <small className={styles.errorMessage}>{quantityError}</small>
                              )}
                          </div>
                      )}

                      <div className={styles.selectionActions}>
                          <button 
                              type="button" 
                              onClick={handleAddProduct}
                              className={`${styles.addProductBtn} ${editingProduct ? styles.updateMode : ''}`}
                          >
                              {editingProduct ? 'Update Product' : 'Add to Order'}
                          </button>
                          {editingProduct && (
                              <button 
                                  type="button" 
                                  onClick={handleCancelEdit}
                                  className={styles.cancelEditBtn}
                              >
                                  Cancel Edit
                              </button>
                          )}
                      </div>
                  </div>
              )}

              {formData.products.length > 0 && (
                  <SelectedProductsTable 
                      formData={formData}
                      handleEditProduct={handleEditProduct}
                      handleRemoveProduct={handleRemoveProduct}
                      availableProducts={availableProducts}
                  />
              )}

              <div className={styles.formActions}>
                  <button 
                      type="submit" 
                      className={styles.submitBtn}
                      disabled={formData.products.length === 0}
                  >
                      Create Order
                  </button>
              </div>
          </form>
      </div>
  </div>
  </>
);
};

export default CreateOrder;