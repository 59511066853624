import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import OrderStatusBadge from '../../components/order/OrderStatusBadge';
import { toast } from 'react-toastify';
import Loader from '../../loader/Loader';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SerialNumberBadge from '../../components/product/SerialNumberBadge';
import { 
  RiMapPinLine, 
  RiCalendarLine,
  RiEdit2Line,
  RiDeleteBin6Line,
  RiFileListLine
} from 'react-icons/ri';
import styles from './OrderForm.module.css';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const UpdateOrder = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const originalOrder = location.state?.order;

  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    orderName: '',
    destination: '',
    fromDate: '',
    untilDate: '',
    products: [],
    requestedBy: '',
    orderStatus: ''
  });

  // Product states
  const [availableProducts, setAvailableProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedSerials, setSelectedSerials] = useState([]);
  const [allSerials, setAllSerials] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const [quantityError, setQuantityError] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [editingProduct, setEditingProduct] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);
  const [dateError, setDateError] = useState('');

  const indexOfLastProduct = currentPage * itemsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - itemsPerPage;
  const currentProducts = filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct);
  const totalPages = Math.ceil(filteredProducts.length / itemsPerPage);

  // Filter states
  const [filters, setFilters] = useState({
    category: '',
    warehouse: '',
    department: ''
  });

  // Initialize form data from the passed order
  useEffect(() => {
    if (!originalOrder) {
      toast.error('No order data provided');
      navigate('/orders');
      return;
    }

    setFormData({
      orderName: originalOrder.orderName, 
      destination: originalOrder.destination,
      fromDate: originalOrder.fromDate.split('T')[0],
      untilDate: originalOrder.untilDate.split('T')[0],
      products: originalOrder.products.map(product => ({
        ...product,
        product: product.product._id || product.product,
        initialQuantity: product.quantity
      })),
      requestedBy: originalOrder.requestedBy,
      orderStatus: originalOrder.orderStatus
    });
  }, [originalOrder, navigate]);

  const applyFilters = useCallback(() => {
    let filtered = [...availableProducts];

    if (searchTerm) {
      filtered = filtered.filter(product => 
        product.productName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        product.productCode.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    if (filters.category) {
      filtered = filtered.filter(product => 
        product.categoryName === filters.category
      );
    }

    if (filters.warehouse) {
      filtered = filtered.filter(product => 
        product.warehouseName === filters.warehouse
      );
    }

    if (filters.department) {
      filtered = filtered.filter(product =>
        product.departmentName === filters.department
      );
    }

    setFilteredProducts(filtered);
  }, [availableProducts, filters, searchTerm]);

  useEffect(() => {
    fetchProducts();
  }, []);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchTerm, filters]);

  useEffect(() => {
    applyFilters();
  }, [filters, searchTerm, availableProducts, applyFilters]);

  useEffect(() => {
  
    const currentDate = new Date();
    const startDate = new Date(formData.fromDate);
    
    // Remove time component for date comparison
    currentDate.setHours(0, 0, 0, 0);
    startDate.setHours(0, 0, 0, 0);
  
    const newStatus = startDate > currentDate ? 'committed' : 'issued';
    
    if (newStatus !== formData.orderStatus) {
      setFormData(prev => ({
        ...prev,
        orderStatus: newStatus
      }));
    }
  }, [formData.fromDate, formData.orderStatus]);
  

  const fetchProducts = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BACKEND_URL}/api/products/getproducts`);
      const products = response.data || [];

      // Fetch warehouse names
      const warehouseIds = [...new Set(products.map(p => p.warehouse))];
      const warehouseResponses = await Promise.all(
        warehouseIds.map(id => axios.get(`${BACKEND_URL}/api/warehouses/${id}`))
      );
      const warehouses = warehouseResponses.reduce((acc, res) => {
        acc[res.data._id] = res.data.name;
        return acc;
      }, {});

      // Fetch category names
      const categoryIds = [...new Set(products.map(p => p.category))];
      const categoryResponses = await Promise.all(
        categoryIds.map(id => axios.get(`${BACKEND_URL}/api/categories/${id}`))
      );
      const categories = categoryResponses.reduce((acc, res) => {
        acc[res.data._id] = res.data.name;
        return acc;
      }, {});

      // Fetch department names
      const departmentIds = [...new Set(products.map(p => p.department))];
      const departmentResponses = await Promise.all(
        departmentIds.map(id => axios.get(`${BACKEND_URL}/api/departments/${id}`))
      );
      const departments = departmentResponses.reduce((acc, res) => {
        acc[res.data._id] = res.data.name;
        return acc;
      }, {});

      const productsWithNames = products.map(product => ({
        ...product,
        warehouseName: warehouses[product.warehouse],
        categoryName: categories[product.category],
        departmentName: departments[product.department]
      }));

      setAvailableProducts(productsWithNames);
      setFilteredProducts(productsWithNames);
    } catch (error) {
      toast.error("Failed to fetch products");
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  // Date validation
    if (name === 'untilDate' || name === 'fromDate') {
      const fromDate = name === 'fromDate' ? value : formData.fromDate;
      const untilDate = name === 'untilDate' ? value : formData.untilDate;

      if (fromDate && untilDate && new Date(untilDate) <= new Date(fromDate)) {
        setDateError('Until date must be after from date');
      } else {
        setDateError('');
      }
    }
  };

  const handleSerialChange = (e) => {
    const serial = e.target.value;
    if (serial) {
      setSelectedSerials(prev => [...prev, serial]);
      setAllSerials(prev => prev.filter(s => s !== serial));
      setQuantity(prev => prev + 1);
    }
  };

  const handleRemoveSerial = (serial) => {
    setSelectedSerials(prev => prev.filter(s => s !== serial));
    setAllSerials(prev => [...prev, serial]);
    setQuantity(prev => prev - 1);
  };

  const handleRemoveProduct = (productId) => {
    setFormData(prev => ({
      ...prev,
      products: prev.products.filter(p => p.product !== productId),
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.products.length === 0) {
      toast.error('Please add at least one product to the order');
      return;
    }

    setIsLoading(true);
    try {
        await axios.patch(`${BACKEND_URL}/api/orders/${originalOrder._id}`,formData);
        toast.success('Order updated successfully!');
        navigate('/orders');
    } catch (error) {
        toast.error(error.response?.data?.message || 'Failed to update order');
    } finally {
        setIsLoading(false);
    }
  };

  const handleSelectProductFromTable = (product) => {
    setSelectedProduct(product);
    if (product.managedBySerial) {
      setAllSerials(product.serialNumberAvailable);
      setSelectedSerials([]);
      setQuantity(0);
    } else {
      setQuantity(1);
    }
  };

  const handleQuantityChange = (e) => {
    let value = parseInt(e.target.value) || 0;
    const product = selectedProduct || availableProducts.find(p => p._id === editingProduct?.product);
  
    if (product) {
      if (value < 1) {
        value = 1;
      } else {
        // Calculate total available quantity
        let totalAvailable = product.quantityAvailable;
        
        // If editing, add back the current order quantity
        if (editingProduct) {
          totalAvailable += editingProduct.quantity;
        }
  
        if (value > totalAvailable) {
          value = totalAvailable;
          setQuantityError(`Maximum available quantity is ${totalAvailable}`);
        } else {
          setQuantityError('');
        }
      }
  
      setQuantity(value);
    }
  };

  const handleEditProduct = (productToEdit) => {
    setEditingProduct(productToEdit);
    const product = availableProducts.find(p => p._id === productToEdit.product);
    setSelectedProduct(product);
    setQuantity(productToEdit.quantity);
    setSelectedSerials(productToEdit.serialNumbers || []);
    
    if (product.managedBySerial) {
      const availableSerials = product.serialNumberAvailable.filter(
        serial => !productToEdit.serialNumbers.includes(serial)
      );
      setAllSerials([...availableSerials]);
    }
  };

  const handleAddProduct = () => {
    if (!selectedProduct) return;

    if (selectedProduct.managedBySerial && selectedSerials.length === 0) {
      toast.error('Please select at least one serial number');
      return;
    }

    if (!selectedProduct.managedBySerial && quantity === 0) {
        toast.error('Please select a quantity greater than 0');
        return;
    }


    const productData = {
      product: selectedProduct._id,
      productName: selectedProduct.productName,
      productCode: selectedProduct.productCode,
      quantity: selectedProduct.managedBySerial ? selectedSerials.length : quantity,
      serialNumbers: selectedSerials,
      returnedQuantity: editingProduct?.returnedQuantity || 0,
      missingQuantity: editingProduct?.missingQuantity || 0,
      returnedSerialNumbers: editingProduct?.returnedSerialNumbers || [],
      missingSerialNumbers: editingProduct?.missingSerialNumbers || [],
      price: selectedProduct.price,
      weight: selectedProduct.weight,
      weightUnit: selectedProduct.weightUnit
    };

    if (editingProduct) {
      setFormData(prev => ({
        ...prev,
        products: prev.products.map(p => 
          p.product === editingProduct.product ? productData : p
        ),
      }));
      setEditingProduct(null);
    } else {
      if (formData.products.some(p => p.product === selectedProduct._id)) {
        toast.error('Product already added to order');
        return;
      }
      setFormData(prev => ({
        ...prev,
        products: [...prev.products, productData],
      }));
    }

    setSelectedProduct(null);
    setSelectedSerials([]);
    setQuantity(1);
  };

  const PaginationControls = () => (
    <div className={styles.paginationControls}>
        <button
            onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
            className={styles.paginationBtn}
        >
            Previous
        </button>
        <span className={styles.pageInfo}>
            Page {currentPage} of {totalPages > 0 ? totalPages : 1}
        </span>
        <button
            onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
            disabled={currentPage === totalPages || totalPages === 0}
            className={styles.paginationBtn}
        >
            Next
        </button>
    </div>
  );

  const ProductsTable = () => (
    <div className={styles.productsTableContainer}>
        <table className={styles.productsTable}>
            <thead>
                <tr>
                    <th>Product Code</th>
                    <th>Product Name</th>
                    <th>Category</th>
                    <th>Warehouse</th>
                    <th>Department</th>
                    <th>Available Quantity</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                {currentProducts.length > 0 ? (
                    currentProducts.map(product => (
                        <tr 
                            key={product._id}
                            className={selectedProduct?._id === product._id ? styles.selectedRow : ''}
                        >
                            <td>{product.productCode}</td>
                            <td>{product.productName}</td>
                            <td>{product.categoryName}</td>
                            <td>{product.warehouseName}</td>
                            <td>{product.departmentName}</td>
                            <td className={`${styles.quantityCell} ${
                                product.quantityAvailable === 0 ? styles.outOfStock :
                                product.quantityAvailable < 10 ? styles.lowStock : ''
                            }`}>
                                {product.managedBySerial 
                                    ? `${product.serialNumberAvailable.length} units` 
                                    : product.quantityAvailable}
                            </td>
                            <td>
                                <button
                                    type="button"
                                    onClick={() => handleSelectProductFromTable(product)}
                                    className={styles.selectProductBtn}
                                    disabled={product.quantityAvailable === 0}
                                >
                                    Select
                                </button>
                            </td>
                        </tr>
                    ))
                ) : (
                    <tr>
                        <td colSpan="7" className={styles.noProducts}>No products available</td>
                    </tr>
                )}
            </tbody>
        </table>
        <PaginationControls />
    </div>
  );

  const SelectedProductsTable = ({ availableProducts }) => (
    <div className={styles.selectedProductsTable}>
      <h3>Selected Products</h3>
      <table>
          <thead>
              <tr>
                  <th>Product Name</th>
                  <th>Product Code</th>
                  <th>Department</th>
                  <th>Quantity</th>
                  <th>Serial Numbers</th>
                  <th>Actions</th>
              </tr>
          </thead>
          <tbody>
            {formData.products.map(product => {
                  const productDetails = availableProducts.find(p => p._id === product.product);
                  return (
                  <tr key={product.product}>
                      <td>{product.productName}</td>
                      <td>{product.productCode}</td>
                      <td>{productDetails?.departmentName || '-'}</td>
                      <td>{product.quantity}</td>
                      <td>
                        <div className={styles.serialList}>
                          {product.serialNumbers.map(serial => (
                            <SerialNumberBadge 
                              key={serial} 
                              serialNumber={serial}
                              isRemovable={false}
                            />
                          ))}
                        </div>
                      </td>
                      <td className={styles.actionColumn}>
                        <RiEdit2Line
                          className={`${styles.actionIcon} ${styles.editIcon}`}
                          onClick={() => handleEditProduct(product)}
                        />
                        <RiDeleteBin6Line
                          className={`${styles.actionIcon} ${styles.deleteIcon}`}
                          onClick={() => handleRemoveProduct(product.product)}
                        />
                      </td>
                  </tr>
                  );
              })}
          </tbody>
      </table>
  </div>
  );

  return (
    <div className={styles.container}>
        {/* Loading overlay */}
        {isLoading && <Loader />}
        
        <div className={styles.card}>
            {/* Card Header with Order Information */}
            <div className={styles.cardHeader}>
                <h2>Update Order: {originalOrder?.orderNumber}</h2>
                <div className={styles.orderStatus}>
                    Current Status: <OrderStatusBadge status={formData.orderStatus} />
                </div>
            </div>

            <form onSubmit={handleSubmit} className={styles.form}>
                {/* Basic Order Information Grid */}
                <div className={styles.formGrid}>
                  <div className={styles.inputGroup}>
                    <label className={styles.inputLabel}>
                        Order Name <span className={styles.required}>*</span>
                    </label>
                    <div className={styles.iconInput}>
                      <RiFileListLine className={styles.inputIcon} />
                      <input
                          type="text"
                          name="orderName"
                          value={formData.orderName}
                          onChange={handleInputChange}
                          placeholder="Enter order name"
                          className={styles.input}
                          required
                      />
                    </div>
                  </div>
                    <div className={styles.inputGroup}>
                        <label className={styles.inputLabel}>
                            Destination <span className={styles.required}>*</span>
                        </label>
                        <div className={styles.iconInput}>
                            <RiMapPinLine className={styles.inputIcon} />
                            <input
                                type="text"
                                name="destination"
                                value={formData.destination}
                                onChange={handleInputChange}
                                placeholder="Enter destination"
                                className={styles.input}
                                required
                            />
                        </div>
                    </div>

                    <div className={styles.inputGroup}>
                        <label className={styles.inputLabel}>
                            From Date <span className={styles.required}>*</span>
                        </label>
                        <div className={styles.iconInput}>
                            <RiCalendarLine className={styles.inputIcon} />
                            <DatePicker
                              selected={formData.fromDate}
                              onChange={date => handleInputChange({
                                  target: { name: 'fromDate', value: date }
                              })}
                              dateFormat="dd/MM/yyyy"
                              placeholderText="dd/mm/yyyy"
                              className={styles.input}
                              required
                              showYearDropdown
                              scrollableYearDropdown
                              yearDropdownItemNumber={15}
                              showPopperArrow={false}
                          />
                        </div>
                    </div>

                    <div className={styles.inputGroup}>
                        <label className={styles.inputLabel}>
                            Until Date <span className={styles.required}>*</span>
                        </label>
                        <div className={styles.iconInput}>
                            <RiCalendarLine className={styles.inputIcon} />
                            <DatePicker
                              selected={formData.untilDate}
                              onChange={date => handleInputChange({
                                  target: { name: 'untilDate', value: date }
                              })}
                              dateFormat="dd/MM/yyyy"
                              placeholderText="dd/mm/yyyy"
                              className={styles.input}
                              required
                              showYearDropdown
                              scrollableYearDropdown
                              yearDropdownItemNumber={15}
                              showPopperArrow={false}
                            />
                        </div>
                        {dateError && <span className={styles.errorMessage}>{dateError}</span>}
                    </div>
                </div>

                {/* Search and Filters Section */}
                <div className={styles.searchSection}>
                    <div className={styles.filtersPanel}>
                        <div className={styles.filterGroup}>
                            <label className={styles.filterLabel}>Search Products</label>
                            <input
                                type="text"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                placeholder="Search by name or code..."
                                className={styles.searchInput}
                            />
                        </div>

                        {/* Dynamic Filters Generation */}
                        {['category', 'department', 'warehouse'].map(filterType => (
                            <div key={filterType} className={styles.filterGroup}>
                                <label className={styles.filterLabel}>
                                    {filterType.charAt(0).toUpperCase() + filterType.slice(1)}
                                </label>
                                <select
                                    value={filters[filterType]}
                                    onChange={(e) => setFilters(prev => ({ 
                                        ...prev, 
                                        [filterType]: e.target.value 
                                    }))}
                                    className={styles.select}
                                >
                                    <option value="">
                                        All {filterType.charAt(0).toUpperCase() + filterType.slice(1)}s
                                    </option>
                                    {[...new Set(availableProducts.map(p => p[`${filterType}Name`]))]
                                        .filter(Boolean)
                                        .sort()
                                        .map(value => (
                                            <option key={value} value={value}>{value}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        ))}
                    </div>
                </div>

                {/* Products Table */}
                <ProductsTable 
                    currentProducts={currentProducts}
                    selectedProduct={selectedProduct}
                    handleSelectProductFromTable={handleSelectProductFromTable}
                />

                {/* Product Selection Panel - Only shown when a product is selected */}
                {selectedProduct && (
                    <div className={styles.productSelectionPanel}>
                        <div className={styles.selectedProductHeader}>
                            <h3>Selected Product: {selectedProduct.productName}</h3>
                            {editingProduct && (
                                <span className={styles.editingBadge}>Editing</span>
                            )}
                        </div>
                        
                        {/* Conditional rendering based on product type */}
                        {selectedProduct.managedBySerial ? (
                            <div className={styles.serialSelection}>
                                <select 
                                    value="" 
                                    onChange={handleSerialChange}
                                    className={styles.serialSelect}
                                >
                                    <option value="">Select Serial Number</option>
                                    {allSerials.map(serial => (
                                        <option key={serial} value={serial}>{serial}</option>
                                    ))}
                                </select>
                                
                                <div className={styles.serialTags}>
                                    {selectedSerials.map(serial => (
                                        <SerialNumberBadge 
                                            key={serial} 
                                            serialNumber={serial} 
                                            onRemove={handleRemoveSerial} 
                                            isRemovable={true} 
                                        />
                                    ))}
                                </div>
                            </div>
                        ) : (
                            <div className={styles.quantityInputGroup}>
                                <label>Quantity:</label>
                                <input
                                    type="number"
                                    value={quantity}
                                    onChange={handleQuantityChange}
                                    min="1"
                                    max={selectedProduct.quantityAvailable}
                                    className={styles.quantityInput}
                                />
                                {quantityError && (
                                    <small className={styles.errorMessage}>{quantityError}</small>
                                )}
                            </div>
                        )}

                        {/* Action Buttons for Product Selection */}
                        <div className={styles.selectionActions}>
                            <button 
                                type="button" 
                                onClick={handleAddProduct}
                                className={`${styles.addProductBtn} ${editingProduct ? styles.updateMode : ''}`}
                            >
                                {editingProduct ? 'Update Product' : 'Add to Order'}
                            </button>
                            {editingProduct && (
                                <button 
                                    type="button" 
                                    onClick={() => {
                                        setEditingProduct(null);
                                        setSelectedProduct(null);
                                        setSelectedSerials([]);
                                        setQuantity(1);
                                    }}
                                    className={styles.cancelEditBtn}
                                >
                                    Cancel Edit
                                </button>
                            )}
                        </div>
                    </div>
                )}

                {/* Selected Products Table - Only shown when products are added */}
                {formData.products.length > 0 && (
                    <SelectedProductsTable 
                        formData={formData}
                        handleEditProduct={handleEditProduct}
                        handleRemoveProduct={handleRemoveProduct}
                        availableProducts={availableProducts}
                    />
                )}

                {/* Form Actions */}
                <div className={styles.formActions}>
                    <button 
                        type="button" 
                        className={styles.cancelBtn}
                        onClick={() => navigate('/orders')}
                    >
                        Cancel
                    </button>
                    <button 
                        type="submit" 
                        className={styles.submitBtn}
                        disabled={formData.products.length === 0}
                    >
                        Update Order
                    </button>
                </div>
            </form>
        </div>
    </div>
  );
};

export default UpdateOrder;